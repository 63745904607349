/* Импорт шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Общие стили */
body, html {

    font-family: 'Roboto', sans-serif;
    color: #fff;
    overflow-x: hidden; /* Предотвращение горизонтальной прокрутки */
}

.auth-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    background-image: url('../public/images/tild6132-6435-4131-b064-343561316231__fa758ba3-dc4c-4b94-9.png');
    background-size: cover;
    background-position: center;
    padding-left: 10%;
}

.auth-form {
    background-color: rgba(255, 255, 255, 0);
    padding: 20px;

    width: 550px;

}

.auth-title {
    сolor: #ffffff;
    font-size: 64px;
    font-family: 'Roboto ',Arial,sans-serif;
    line-height: 1.3;
    font-weight: 800;
    text-transform: uppercase;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-bottom: 10px;
}
.instruction{
    position: relative;


    padding-top: 20px;
}
.instruction a{

    color:rgba(255, 255, 255, 0.5);
    font-size: 18pt;

}
.auth-form label {
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
    color: #fff;
}

.auth-form input[type="text"],
.auth-form input[type="password"] {
    width: 70%;
    margin-bottom: 10px;
    padding: 13px;
    border: 1px solid #ccc;
    font-size: 18px;
    box-sizing: border-box;
}

.auth-form button[type="submit"] {
    width: 30%;
    padding: 13px;
    background-color: #21ffe4;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
    text-transform: uppercase;
    margin-top: 15px;
}

.auth-form button[type="submit"]:hover {
    background-color: #1dd1d1;
}


.message-box {
    display: none;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ff0000;
    background-color: rgba(255, 0, 0, 0.05);
    color: #ff0000;
    border-radius: 0px;
    font-size: 18px;
}

.message-box.visible {
    display: block;
}


/* Стили для Navbar */
.navbar {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.1); /* Полупрозрачный фон */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.navbar a {
    color: #fff;
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
}

.navbar a:hover {
    text-decoration: underline;
}


/* Стили для контейнера с вдавленным верхом */
.background {
    position: fixed;
    top: 0;
    left: 0;
    right: -17px;
    width: 100%;
    height: 100%;
    background-image: url('../public/images/tild6132-6435-4131-b064-343561316231__fa758ba3-dc4c-4b94-9.png');
    background-size: cover;
    background-position: center;
    z-index: -1; /* Размещение фона позади контента */
}
/*
::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #FF0000;
}
*/

.dash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.notch-container {
    background-color: rgba(255,255,255,1);
    border-radius: 0px;
    padding: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 200px;
}
.full-height{
    height:100%!important;
    padding-top: 10px;
    padding-bottom: 10px;
}
.notch {
    width: 100vw;
    height: 40px;
    background-size: cover;
    background-position: center;
    z-index: -1; /* Размещение фона позади контента */
    position: absolute;
    top: -40px;
    left: 0;
    background-image: url('../public/images/Vector_hero-bottom.svg');
}
.notch-after {

    width: 100vw;
    height: 40px;
    background-size: cover;
    background-position: center;
    z-index: -1; /* Размещение фона позади контента */
    position: absolute;
    bottom: -39px;
    left: 0;
    background-image: url('../public/images/Vector_77.svg');
    -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg);        /* FF */
    -o-transform: rotate(180deg);          /* Opera */
    -ms-transform: rotate(180deg);         /* IE9 */
    transform: rotate(180deg);             /* W3C compliant browsers */
}


.notch-container h1 {
    color: black;
    text-align: center;
}

.notch-container p {
    color: black;
    text-align: justify-all;
}

.notch-container .button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    width: 250px;
    text-color:#ffffff;
    background-color: #21ffe4;
    border: none;
    border-radius: 0px;
    color: black;
    font-size: 16px;
    cursor: pointer;
}

.notch-container .button:hover {
    background-color: #1dd1d1;
}
.instruction-main{

}

.table-title{
    margin-top: 70px;
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 20px;

}
.table-title img{
    margin-left: 0;
    margin-right: 5px;
    height: 50px;
}

.table-title .brand{
    text-wrap: wrap;
    font-size: 18pt;
    width: 120px;
    font-weight: bold;
    line-height: 19pt;
    /*@todo linespacing */
}

.table-title .t-title{
    font-size: 16pt;
    font-weight: bolder;
    margin-top: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}


.table-container{

    position: relative;
    max-height: 70vh;
    width: 70%;
    overflow-y: auto;
}
table {
    text-align: center;
    position: relative;
    border-collapse: collapse;
    font-weight: bolder;

    color: white!important;
    font-family: 'Roboto ',Arial,sans-serif;
    width: 100%;
}
th, td {
    padding:    1rem;
}
th {
    background-color: rgba(0, 0, 0, 1);
    position: sticky;
    top: 0;
    color: white!important;
    z-index: 1;
    /*box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);*/
}
th,td a{
    color: white;
}

td{
    background-color: rgba(0, 0, 0, 0.1);
}
td.success{
    color: #65FF6B!important;

}
td.loss{
    color: #FF5454!important;

}
td.fail{
    color: #939393!important;

}
.table-container::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb {
    background: #939393;
    border-radius: 0;
    height: 50px!important;
}
tr{
    position: relative;
}
tbody tr::after {
    position: absolute;
    content: "";
    bottom: 0;
    left:   10%;
    margin-left: -7%;
    width: 95%;
    height: 1px;
    border-bottom: 1px solid rgba(255,255,255,0.6);
}




/* Анимации перехода */
.page-enter {
    transform: translateX(100%);
}

.page-enter-active {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
}

.page-exit {
    transform: translateX(0);
}

.page-exit-active {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
}



/* Адаптивные стили */
@media (max-width: 768px) {
    .auth-container {
        justify-content: center;
        padding-left: 0;
    }

    .auth-form {
        margin-left: auto;
        width: 100%;
    }
    .form{
        margin: auto;
    }
    .auth-form input[type="text"],
    .auth-form input[type="password"] {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        font-size: 16px;
        box-sizing: border-box;
    }

    .auth-form button[type="submit"] {
        width: 50%;
        padding: 10px;
        background-color: #21ffe4;
        border: none;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        color: #000;
        text-transform: uppercase;
    }
    .auth-title {
        сolor: #ffffff;
        font-size: 32px;
        font-family: 'Roboto ',Arial,sans-serif;
        line-height: 1.3;
        font-weight: 800;
        text-transform: uppercase;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        margin-bottom: 10px;
    }
}

.sort-button {
    display: inline;
    background: none;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.sort-button.ordered::after {
    content: " ▼";
}

.sort-button.ordered2::after {
    content: " ▲";
}

/*    background-image: url('../public/images/tild6132-6435-4131-b064-343561316231__fa758ba3-dc4c-4b94-9.png');*/
